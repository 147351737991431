import React from 'react';

export default () => (
  <section className='container-fluid black_more'>
        <div className='row m-10-hor'>

        <div className='col-12 text-center'>
          <div className='subheading'>Our Pricing</div>
          <div className='heading'>Easy Pricing</div>
          <div className='row'>
            <div className='col-md-8 mx-auto'>
              <p className='content'>
                Decide to put pricing or not for whatever reasons or products/services
              </p>
            </div>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='pricelist'>
            <div className="heading">Basic</div>
            <div className="bigprice"><span className='text-gradient'>$29</span> <small>Month</small></div>
            <ul className="list">
              <li><i className="fa fa-check"></i> Full Access</li>
              <li><i className="fa fa-check"></i> We are awesome</li>
              <li><i className="fa fa-check"></i> You are awesome</li>
              <li><i className="fa fa-check"></i> We are awesome</li>
              <li><i className="fa fa-check"></i> You are awesome</li>
            </ul>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='pricelist'>
            <div className="heading">Standard</div>
            <div className="bigprice"><span className='text-gradient'>$399</span> <small>One-Time</small></div>
            <ul className="list">
            <li><i className="fa fa-check"></i> Full Access</li>
            <li><i className="fa fa-check"></i> We are awesome</li>
            <li><i className="fa fa-check"></i> You are awesome</li>
            <li><i className="fa fa-check"></i> We are awesome</li>
            <li><i className="fa fa-check"></i> You are awesome</li>
            </ul>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='pricelist'>
            <div className="heading">Advanced</div>
            <div className="bigprice"><span className='text-gradient'>$498</span> <small>Lifetime</small></div>
            <ul className="list">
            <li><i className="fa fa-check"></i> Full Access</li>
            <li><i className="fa fa-check"></i> We are awesome</li>
            <li><i className="fa fa-check"></i> You are awesome</li>
            <li><i className="fa fa-check"></i> We are awesome</li>
            <li><i className="fa fa-check"></i> You are awesome</li>
            </ul>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='pricelist'>
            <div className="heading">Premium</div>
            <div className="bigprice"><span className='text-gradient'>$59</span> <small>Month</small></div>
            <ul className="list">
            <li><i className="fa fa-check"></i> Full Access</li>
            <li><i className="fa fa-check"></i> We are awesome</li>
            <li><i className="fa fa-check"></i> You are awesome</li>
            <li><i className="fa fa-check"></i> We are awesome</li>
            <li><i className="fa fa-check"></i> You are awesome</li>
            </ul>
          </div>
        </div>

        </div>
    </section>

);
