import React, {useState} from 'react';
import emailjs from 'emailjs-com';
import { Link } from '@reach/router';
import Footer from '../components/footer';
import toast from 'react-hot-toast'
import axios from 'axios'


export default function() {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  function sendMessage(e){
    e.preventDefault();
    
    const data = {
      name:name,
      email:email,
      message:message,
      phone:"519519519"
    }
    const jsonData = JSON.stringify(data);
    toast.promise(
      axios.post('https://api.sphoun.ca/contact', jsonData),
      {
        loading:"Sending Sphoun your message...",
        success:"Message sent successfully...",
        error:"Your message could not be sent..."
      }
    )

  }

  function sendEmail(e) {

    const success = document.getElementById("success");
    const button = document.getElementById("buttonsent");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_csfdEZiA', e.target, 'user_zu7p2b3lDibMCDutH5hif')
      .then((result) => {
          console.log(result.text);
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
      }, (error) => {
          console.log(error.text);
          failed.classList.add('show');
      });
  }

  return (
    <div>
    <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'/images/loftstyle.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>Contact</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <Link className='link' to="/home">Home</Link>
                  <span className='dash'>/</span>
                  <span>Contact</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
           <div className="text-side">
             <h2>Get in Touch</h2>
              <p>Anything tech related shoot me a message!</p>

              <div className='address'>
                <div className='heading'>Our Offices</div>
                <div className='list'>
                  <i className="fa fa-map-marker"></i>
                  Windsor Ontario
                </div>
                <div className='list'>
                  <i className="fa fa-envelope-o"></i>
                  <a href='mailto:sphoun@sphoun.ca' target='_blank' rel='noopener noreferrer'>
                    sphoun@sphoun.ca
                  </a>
                </div>
                <div className='list'>
                  <i className="fa fa-phone"></i>
                  519-995-1984
                </div>
              </div>
           </div>
          </div>
          <div className='col-md-6'>
            <div className="form-side">
              <form className="formcontact" onSubmit={sendMessage}>
                <label>Name</label>
                <input type="text" name="user_name" required
                  value={name} onChange={(e) => {setName(e.target.value)}}
                />
                <label>Email</label>
                <input type="email" name="user_email" required
                  value={email} onChange={(e) => {setEmail(e.target.value)}}
                />
                <label>Message</label>
                <textarea name="message" required rows={6}
                  value={message} onChange={(e) => {setMessage(e.target.value)}}
                />
                <button type="submit" id='buttonsent'>
                  <span className="shine"></span>
                  <span>Send</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
