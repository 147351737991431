import React from 'react';
import Slider from '../components/sliderhome';
import Carouselteam from '../components/carouselteam';
import Bannercontact from '../components/bannercontact';
import Pricing from '../components/pricing';
import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';


export default () => (
  <div>
      <section className="jumbotron jumbomain">
          <Slider />
          <div className="icon-scroll-wraper">
            <div className="icon-scroll">
              <div className="icon-scroll-screen"></div>
            </div>
          </div>
      </section>

      <section className='container-fluid p-0'>
        <div className='row'>

          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./images/bathroom2-dark.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <div className='heading'>
                  Concept Design
                </div>
                <div className='con-text'>
                  Concept design is awesome awesome awesome.Concept design is awesome awesome awesome.Concept design is awesome awesome awesome.
                </div>
                <div className='link'>
                  <span className="shine"></span>
                  Read More
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./images/moderninterior-dark.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <div className='heading'>
                  Interior
                </div>
                <div className='con-text'>
                  Awesome info and comment about interior design
                </div>
                <div className='link'>
                  <span className="shine"></span>
                  Read More
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./images/housedesign-dark.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <div className='heading'>
                  Architecture
                </div>
                <div className='con-text'>
                  Awesome info and comment about architecture
                </div>
                <div className='link'>
                  <span className="shine"></span>
                  Read More
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className='container-fluid black pb-0'>
        <div className='row m-10-hor'>

          <div className='col-md-5'>
            <div className='heading'>
              Why <span className='br'></span>Choose<span className='br'></span> Us?
            </div>
          </div>

          <div className='col-md-7'>
            <div className='content'>
              We are so awesome at working on homes.
              We are so awesome at working on homes.
              We are so awesome at working on homes.
              We are so awesome at working on homes. We are so awesome at working on homes.
              We are so awesome at working on homes.
            </div>
            <div className='content'>
              We are so awesome at working on homes. We are so awesome at working on homes. We are so awesome at working on homes.
            </div>
          </div>

        </div>
    </section>

    <section className='container-fluid pt-0 black'>
        <div className='row m-10-hor'>
         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              01.
            </div>
            <div className='heading'>
              MODERN DESIGN
            </div>
            <div className='content'>
              Use this platform to outline your design or renovation skills
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              02.
            </div>
            <div className='heading'>
              Real Estate
            </div>
            <div className='content'>
              Real estate agents and teams can use this as a landing
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              03.
            </div>
            <div className='heading'>
              More Reasons Here
            </div>
            <div className='content'>
              We are so awesome. We are so awesome. We are so awesome.
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              04.
            </div>
            <div className='heading'>
              More Reasons Here
            </div>
            <div className='content'>
              We are so awesome. We are so awesome. We are so awesome.We are so awesome. We are so awesome. We are so awesome.We are so awesome. We are so awesome. We are so awesome.
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              05.
            </div>
            <div className='heading'>
              More Reasons Here
            </div>
            <div className='content'>
              You are so awesome. You are so awesome. You are so awesome.
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              06.
            </div>
            <div className='heading'>
              24 / 7 Support
            </div>
            <div className='content'>
              Because you are so awesome. Because you are so awesome. Because you are so awesome. Because you are so awesome.
            </div>
          </div>
         </div>

        </div>
    </section>

    <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className='col-12 text-center'>
            <div className='subheading'>Our team</div>
            <div className='heading'>About Our Team</div>
            <div className='row'>
              <div className='col-md-8 mx-auto'>
                <p className='content'>
                  Our team is the best. They're all so awesome at what they do. They are so awesome. This is our team. Check them out. Awesome awesome awesome Awesome awesome awesome.
                </p>
              </div>
            </div>
          </div>
          <Carouselteam />
        </div>
    </section>

    <Bannercontact/>
    <Pricing />
    <Testimony />
    <Footer />

  </div>
);
